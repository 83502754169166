@import "variables";
@import "mixins";

html {
   position: relative;
   min-height: 100%;
   
}

.main {
   padding: calc(#{$header-height} + #{$spacing-md}) $spacing-md $spacing-md $spacing-md;
}

body {
   display: flex !important;
   width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available;
    padding: 0;
    margin: 0;
}

#root {
 display: flex;
 flex: 1;
 flex-direction: column;

}

/*.slider-container {
   width: 100%;
   height: 100%;*/
   
   /* Add this */
  /* position:fixed;
   top:0;
   left:0;
   
   }*/